:root {
  --Brand-Colors-Dark-purple: #2C0A34;
  --Brand-Colors-VID-Accent: #CD0494;
  --Brand-Colors-Middle-purple: #D99FF9;
  --Purple-White: #E9E7EB;
  --Gray: #ABA8AF;
  --light-background: #F4F2F5;
  --Font-Purple: #FFD4E5;
  --popup-bg: #25062DE5;
}

*[onclick]{
  cursor: pointer; 
}

a[onclick]{
  color: -webkit-link;
}

.footer a[onclick]{
  color: var(--light-background);
}

html{
  overflow-x: hidden;
}

body{
  flex-direction: row;
  width: 100vw;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 64px;
  background-color: var(--light-background);
  isolation: isolate;
  margin: 0;
  display:flex;
  min-height: 100dvh;
  align-items: center;
  box-sizing: border-box;
}

#social-header-bg{
  background: var(--Brand-Colors-Dark-purple);
  width: 100%;
  position: fixed;
  height: 40vh;
  z-index: -1;
  top: 0;
  left: 0;
}

#social-header{
  color: var(--light-background);
  height: 64px;
  width: 100%;
  margin-bottom: -16px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  font-stretch: condensed;
  position: fixed;
  top: 0;
  z-index: -1;
}

#social-header a, .footer a{
  line-height: 0;
}

#social-header a:first-child{
  margin-left: 48px;
}

#social-header .flex-right{
  margin-left: auto;
  margin-right: 48px;
  text-align: right;
}

.content-card{
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  margin-bottom: -16px;
  overflow: hidden;
  padding-bottom: 96px;
}

.content-card > *{
  padding: 48px;
}

#header-bg{
  border-radius: 16px 16px 0px 0px;
  background-color: var(--light-background);
  width: 100%;
  margin-top: -143px; /*redo?*/
  height: 150px;
  z-index: -1;
}

.content-card.first{
  border-radius: 0px;
}

.content-card.dark{
  background-color: var(--Brand-Colors-Dark-purple);
  color: white;
}

.content-card.light{
  background-color: var(--light-background);
  color: var(--Brand-Colors-Dark-purple);
}

#header{
  display: flex;
  padding: 40px 48px 48px 48px;
  align-items: center;
  justify-content: space-between;
}

#header-menu, .buy-button, #header-logo, #header-faq, #hamburger-menu{
  margin-top: 40px;
  margin-bottom: 48px;
  z-index: 3;
}

#hamburger-menu{
  margin-right: 48px;
}

#mobile-menu{
  display: none;
}

#mobile-menu {
  position: absolute;
  top: 214px;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 20;
  background-color: var(--popup-bg);
  overflow-x: hidden;
  overflow-y: scroll;
  color: var(--Brand-Colors-Dark-purple)
}

#mobile-menu > *{
  margin: 20px auto;
  font-size: 24px;
  font-stretch: condensed;
  font-weight: 600;
  line-height: 32px;
  position: relative;
}

#mobile-menu > *::before{
  content: "";
  position: absolute;
  top: -20px;
  left: -50vw;
  right: -50vw;
  bottom: -20px;
  background-color: var(--light-background);
  z-index: -1;
  pointer-events: none;
}

#mobile-menu > .flex-end{
  background: none;
  height: -webkit-fill-available;
  height: -moz-fill-available;
  height: fill-available;
  width: 100%;
}

#mobile-menu > .flex-end::before{
  display: none;
}

#hamburger-menu #close, #hamburger-menu.opened #open{
  display: none;
}

#hamburger-menu.opened #close, #hamburger-menu #open{
  display: initial;
}

#mobile-menu .buy-button, #mobile-menu .social-buttons{
  display: none;
}

#header-logo{
  padding-left: 48px;
}

#header-menu{
  display: flex;
  gap: 32px;
  color: var(--Brand-Colors-Dark-purple, #2C0A34);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  font-stretch: condensed;
  margin-left: auto;
  margin-right: auto;
}

#header-faq{
  margin-right: 40px;
}

.buy-button{
  background-color: var(--Brand-Colors-VID-Accent);
  padding: 12px 32px 11px 32px;
  border-radius: 62px;
  cursor: pointer;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-stretch: condensed;
  margin-right: 32px;
  position: sticky;
  top: 25px;
  z-index: 10;
  transition: transform 0.3s ease;
}

.precarga-button{
  margin-left: auto;
  margin-right: 16px;
  border-radius: 62px;
  border: 1px solid #2C0A3433;
  background-color: var(--light-background);
  color: var(--Brand-Colors-Dark-purple);
  text-wrap: nowrap;
}

.oasis-button{
  margin-left: auto;
  margin-right: auto !important;
  margin-bottom: 40px !important;
  margin-top: 0 !important;
  border-radius: 62px;
  width: fit-content;
  position: relative;
}

.buy-button:hover{
  transform: scale3d(1.1, 1.1, 1.1);
}

.mobile.buy-button{
  display: none;
}

#countdown-wrapper .header{
  font-size: 56px;
  font-stretch: condensed;
  font-weight: 700;
  line-height: 64px;
}

#countdown-wrapper #countdown{
  font-size: 40px;
  font-stretch: condensed;
  font-weight: 700;
  line-height: 48px;
}

#countdown-wrapper + .video{
  height: 500px;
  width: 500px;
  background-position: center;
  background-size: cover;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

#countdown-wrapper + .video video{
  width: 100%;
  object-fit: cover;
}

.section-title{
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  line-height: 52px; /* 130% */
  font-stretch: condensed;
  transform: rotate(-10deg);
  margin: 80px;
}

.horizontal-slider{
  overflow: scroll;
  display: flex;
  gap: 100px;
  align-items: center;
  padding: 0 48px 152px 48px;
}

.artist{
  width: 320px;
  height: 320px;
  border-radius: 16px;
  background-size: cover;
  position: relative;
  flex: none;
  transition: filter 1s ease;
  filter: saturate(0);
}

.artist:hover{
  filter: saturate(1);
}

.artist:nth-of-type(even){
  width: 200px;
  height: 200px;
}

.artist::after{
  content: attr(name);
  transform: rotate(180deg);
  position: absolute;
  right: -25px;
  bottom: 0;
  text-orientation: sideways-right;
  writing-mode: vertical-lr;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  font-stretch: condensed;
}

.horario{
  width: 425px;
  aspect-ratio: 1;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 16px;
}

.ticker{
  width: 120%;
  height: 64px;
  padding: 0;
  margin: 0;
  margin-left: 50%;
  transform: translateX(-50%) rotate(-3.5deg);
  background: var(--Brand-Colors-Middle-purple);
}

.ticker-2nd{
  transform: translate(-50%, -76px) rotate(3.5deg); /*5deg?*/
  background: var(--Brand-Colors-Dark-purple);
  z-index: 15;
  position: relative;
  margin-bottom: 96px;
}

.ticker svg{
  font-family: 'Hubot Sans';
  font-size: 24px;
  font-stretch: condensed;
  font-weight: 900;
  fill: var(--Font-Purple);
}

.flex-wrapper{
  display: flex;
  gap: 100px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#mini-explainer, #mini-faq{
  max-width: 500px;
  width: 40%;
  min-width: auto;
}

#mini-explainer .header{
  font-size: 40px;
  font-stretch: condensed;
  font-weight: 700;
  line-height: 52px;
}

#mini-explainer .body{
  font-size: 18px;
  font-stretch: condensed;
  font-weight: 300;
  line-height: 26px;
  padding: 32px 0;
}

#mini-explainer .more-button{
  display: flex;
  height: 40px;
  padding: 6px 16px 6px 22px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 62px;
  border: 1px solid var(--Purple-White);
  width: fit-content;
}

#mini-explainer .more-button span{
  font-stretch: condensed;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
}

.question{
  padding: 20px 24px;
  border-radius: 8px;
  background-color: var(--light-background);
  max-width: 800px;
  margin: auto;
}

.question:hover{
  background: var(--Purple-White);
}

.question b{
  display: flex;
  font-size: 20px;
  font-stretch: condensed;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
}

.question b:after{
  content: '\FF0B';
  height: 36px;
  width: 36px;
  min-width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  border: 1px solid var(--Purple-White);
  transition: transform 0.3s ease;
}

.question:hover b:after{
  background-color: var(--Brand-Colors-Middle-purple);
  color: white;
}

.question.expand b:after{
  transform: rotate3d(0,0,1,135deg);
}

.question .answer{
  display: block;
  font-stretch: condensed;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  transition: max-height 1s ease;
  overflow: hidden;
  max-height: 0;
}

.question.expand .answer{
  max-height:260px; /*TODO: use the exact height of each div with JS*/
}

.question .answer:before{
  content: '';
  display: block;
  height: 20px;
}

.grid{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.bodega{
  font-size: 24px;
  font-stretch: condensed;
  font-weight: 600;
  line-height: 32px;
  width: 25%;
  height: 288px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  background-size: contain, 66%;
  background-position: center;
  background-repeat: no-repeat;
}

.bodega:hover{
  background-image: url('images/botella.png'), url('images/star.png');
}

.partner{
  font-size: 16px;
  font-stretch: condensed;
  font-weight: 400;
  line-height: 20px;
  width: 12.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  color: var(--Gray);
  padding: 26px 0;
  min-width: 150px;
}

.partner .image{
  margin: 26px 0;
  height: 50px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.partner-big{
  width: 16%;
  min-width: 200px;
}

.partner-big .image{
  height: 72px;
  width: 144px;
}

.flex-break{
  width: 100%;
  height: 0;
}

.footer{
  display: flex;
  padding: 48px 64px;
  margin: 0px 48px 49px 48px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  background: var(--Brand-Colors-Dark-purple);
  border-radius: 16px;
  color: var(--light-background);
  font-size: 20px;
  font-stretch: condensed;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  z-index: 3;
  flex-wrap: wrap;
}

.footer .logo{
  margin-right: auto;
  font-size: 12px;
}

.footer .legal{
  margin-right: 50px;
}

.section{
  display: none;
}

.activity{
  display: flex;
  padding: 0;
  align-items: center;
  gap: 32px;
  color: var(--Brand-Colors-Dark-purple);
  font-stretch: condensed;
  margin: auto;
  margin-bottom: 64px;
  flex-wrap: wrap;
  justify-content: center;
}

.activity .image{
  width: 427px;
  aspect-ratio: 427 / 322;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}

.activity .text{
  max-width: 427px;
}

.activity .title{
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  padding-bottom: 8px;
}

.activity .subtitle{
  text-align: justify;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.activity .body{
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 16px 0;
  text-wrap: wrap;
}

.activity .moreinfo{
  text-align: right;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-decoration-line: underline;
  padding-bottom: 32px;
}

.activity .button{
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  display: flex;
  height: 40px;
  padding: 12px 16px 11px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 62px;
  background: var(--Brand-Colors-Dark-purple);
  box-sizing: border-box;
  width: fit-content;
}

.content-card.section{
  min-height: calc(100vh - 400px);
}

#section-contact div{
  max-width: 600px;
  margin: 20px auto;
}

br{
  content: "";
  display: block;
  margin: 16px 0;
}

a{
  text-decoration: none;
}

@font-face {
  font-family: 'Hubot Sans';
  src:
    url('Hubot-Sans.woff2') format('woff2 supports variations'),
    url('Hubot-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 100% 80%; /*seguro que es 80%?*/
}
html {
  font-family: 'Hubot Sans';
}

/*responsive*/
@media (max-height: 460px) { /* phone landscape */
  .buy-button{
    position: initial;
  }

  .footer{
    margin-top: -200px;
  }

  .landing:not(:has(~ .landing)),
  .section{
    padding-bottom: 232px;
  }

  .question {
    max-width: 500px;
  }

  #header-logo{
    padding-left: 24px;
  }

  .buy-button, #hamburger-menu{
    margin-right: 24px !important;
  }
}

@media (max-width: 980px) { /* tablet portrait */
  body > #header-menu,
  body > #header-faq{
    display: none;
  }

  #header-logo svg{
    width: 121px;
    height: 30px;
  }

  .buy-button{
    font-size: 16px;
    padding: 8px 24px;
    margin-right: 10px;
  }

  #hamburger-menu{
    display: initial;
    margin-right: 48px;
    width: 48px;
  }

  #mobile-menu .buy-button,
  #mobile-menu .social-buttons{
    display: none;
  }

  .footer{
    justify-content: flex-start;
  }

  .footer .logo{
    flex-basis: 100%;
  }

  .footer .legal {
    margin-right: auto;
  }

  #mini-explainer, #mini-faq{
    width: 100%;
  }
}

@media (max-width: 460px) { /* phone portrait */
  #social-header a, 
  body > #header-menu, 
  body > #header-faq,
  /* .footer, */
  body > .buy-button,
  .footer > a{
    display: none;
  }

  body{
    padding-top: 48px;
    padding-bottom: 73px;
  }

  #social-header{
    height: 48px;
  }

  #social-header .flex-right{
    margin-right: auto;
  }

  #header-logo{
    padding-left: 16px;
  }

  #header-logo svg{
    width: 164px;
  }

  #header-bg{
    margin-top: -72px;
    height: 72px;
  }

  #hamburger-menu{
    display: initial;
    margin-right: 16px;
  }

  #mobile-menu{
    position: fixed;
    top: 120px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--light-background);
    padding:16px;
    flex-direction: column;
    align-items: center;
    z-index: 20;
    padding-bottom:76px;
  }

  #mobile-menu .buy-button{
    display: initial;
    margin-right: 0;
    margin-bottom: 20px;
    width: -webkit-fill-available;
    width: -moz-fill-available;
    width: fill-available;
  }

  .mobile.buy-button{
    position: fixed;
    display: flex;
    bottom: 16px;
    right: 16px;
    left: calc(50vw + 8px);
    top: auto;
    margin: 0;
    font-size: 16px;
    line-height: 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mobile.buy-button svg{
    margin-right:1ex;
    margin-bottom: 2px;
  }
  
  .mobile.buy-button.precarga-button{
    left: calc(50vw + 8px);
    right: 16px;
  }

  #mobile-menu > *{
    margin: 20px 0;
    font-size: 24px;
    font-stretch: condensed;
    font-weight: 600;
    line-height: 32px;
  }

  #mobile-menu > a{
    flex-basis: 25%;
    margin: 24px 0;
    text-align: center;
  }

  #mobile-menu > .social-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    gap: 32px;
    line-height: 0;
  }

  #header-menu, .buy-button, #header-logo, #header-faq, #hamburger-menu {
    margin-top: 16px;
    margin-bottom: 0px;
  }

  .flex-wrapper{
    justify-content: space-between;
    gap: 16px;
  }

  #countdown-wrapper .header {
    font-size: 32px;
    line-height: 40px;
  }

  #countdown-wrapper #countdown {
    font-size: 16px;
    line-height: 24px;
  }

  #countdown-wrapper + .video {
    height: auto;
    aspect-ratio: 1 / 1;
  }

  .content-card.landing > *{
    padding: 16px;
  }

  .content-card.section{
    padding: 16px;
    min-height: calc(100vh - 300px);
  }

  .section-title{
    font-size: 32px;
    line-height: 42px;
    margin: 32px 0 0 0;
  }

  .section .section-title{
    margin: 0 auto;
    padding: 32px;
    width: 80%;
  }

  .horizontal-slider {
    margin-bottom: 50px;
    gap: 48px;
    padding-right: 48px !important;
  }

  .artist, .artist:nth-of-type(even){
    width: 70vw;
    height: auto;
    aspect-ratio: 1 / 1;
  }

  .artist{
    filter: none;
  }

  .ticker{
    /* height: 52px; */
    width: 240%;
    transform: translateX(-50%) scale(0.7) rotate(-8deg);
    padding: 0;
  }

  .ticker-2nd{
    transform: translate(-50%, -94px) scale(0.7) rotate(8deg);
    margin-bottom: -72px;
    margin-top: 6px;
  }

  #mini-explainer .more-button{
    width: initial;
  }

  .bodega{
    background-image: url('images/botella.png'), url('images/star.png');
  }

  .grid{
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    justify-content: flex-start;
  }

  .bodega, .partner{
    min-width: 200px;
    margin: 0 24px;
  }

  .footer{
    margin: 16px;
    padding: 20px 24px;
  }

  .footer .legal{
    margin-right: auto;
    font-size: 18px;
  }

  .activity .button{
    width: initial;
    padding: 24px 16px;
  }

}

@media (min-width: 600px) { /* phone landscape and above */
}
/*end responsive*/

.body-noscroll {
  overflow: hidden;
  max-height: 100vh;
}

.popup {
  position: relative;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  z-index: 1;
  display: none;
  max-width: 90%;
  width: 900px;
  margin: auto;
}

#popup-wrapper {
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: scroll;
  display: none;
  box-sizing: border-box;
  background-color: var(--popup-bg);
  z-index: 100;
  padding: 50px;
}

#popup-bg{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#popup-wrapper .back-button{
  display: none;
}

#popup-actividades{
  padding: 20px 0;
}

#popup-actividades iframe{
  width:100%;
  height: 2100px;
}

@media (max-width: 460px) { /* phone portrait */
  #popup-wrapper{
    padding: 64px 16px;
  }

  #popup-wrapper .back-button{
    display: initial;
  }

  .popup{
    max-width: 100%;
    box-sizing: border-box;
  }
}

canvas.webgl{
  border-radius: 16px;
  width: 100vw;
  height: 100vh;
  padding: 0;
  border-radius: 16px;
}

#section-map{
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  height: calc(100dvh - 64px - 128px);
  min-height: 360px;
  position: relative;
}

@media (max-width: 460px) { /* phone portrait */
  #section-map{
    height: calc(100dvh - 64px - 72px);
  }

  #section-map::before{
    content: "";
    position: absolute;
    top: 0px;
    height: 16px;
    width: 100%;
    background-color: var(--light-background);
    z-index: 10;
  }
}

body.map-opened{
  height: calc(100dvh);
  min-height: calc(100dvh);
}

#section-map .point
{
    position: absolute;
    top: 50%;
    left: 50%;
}

canvas.webgl, #section-map .point{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#section-map .point div{
  position: absolute;
  opacity: 0;
  padding: 1ex 2ex;
  transition: opacity 0.3s ease;
  color:white;
  background-color: var(--Brand-Colors-VID-Accent);
  font-size: 10px;
  border-radius: 10px;
  white-space: nowrap;
  transform: translate3d(-50%, -50%, 0);
  pointer-events: none;
}

#section-map .point div.number{
  padding: 2ex;
}

#section-map.near .point .name{
  opacity: 1;
}

#section-map.far .point .number{
  opacity: 1;
}

.buy-button:not(.precarga-button){
  opacity: 0;
  pointer-events: none;
  margin-left: auto;
}

.hidden{
  display: none !important;
}
